<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Blog Category Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Name" 
          :danger="validation.name?true:false"
          :danger-text="validation.name?validation.name[0]:''"
          :value="form.name"
          @input="updateForm({key: 'name', value: $event})" />
      </div>
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Slug" 
          :danger="validation.slug?true:false"
          :danger-text="validation.slug?validation.slug[0]:''"
          :value="form.slug"
          @input="updateForm({key: 'slug', value: $event})" />
      </div>
      <div class="vx-col w-full md:w-3/3">
        <vs-input 
          class="w-full mt-4" 
          label="Display Name" 
          :danger="validation.display_name?true:false"
          :danger-text="validation.display_name?validation.display_name[0]:''"
          :value="form.display_name"
          @input="updateForm({key: 'display_name', value: $event})" />
      </div>
  </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'

export default {  
  components: {
    vSelect,
    vOption
  },

  computed: {
    ...mapGetters({
        form: 'blog_category/getFormObj',
        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'blog_category/updateForm',
    })
  }
}
</script>
